<template>
  <div id="app" :class="{ 'no-scroll': $store.state.modalIsOpen }">
    <a href="#body" class="skip-to-main-content-link">
      {{ $t('app.skip-to-main-content') }}
    </a>

    <layout-header />

    <main class="main-body" id="body">
      <nals-upgrade-available v-if="$store.state.nals.isConnected && !$store.getters['rehearsalSession/isInSession']"/>
      <transition name="component-fade" mode="out-in">
        <router-view>
          <loading-spinner/>
        </router-view>
      </transition>
    </main>

    <layout-footer />

    <modal-dialog-wrapper />
  </div>
</template>

<script>
import LayoutHeader from './components/layout/LayoutHeader.vue'
import LayoutFooter from './components/layout/LayoutFooter.vue'
import NalsUpgradeAvailable from './components/NalsUpgradeAvailable.vue'
import LoadingSpinner from './components/LoadingSpinner.vue'
import eventBus from './eventBus.js'
import NalsDebugConsole from './components/NalsDebugConsole.vue'
import NALS from './lib/nals-core.js'
import API from './api'
import ModalDialogWrapper from './components/ModalDialogWrapper.vue'

export default {
  name: 'App',
  metaInfo () {
    return {
      title: this.$t('app.default-page-title'),
      titleTemplate: '%s | NuCorder'
    }
  },
  data: function () {
    return {
      onlinePingInterval: -1,
      updateConfigInterval: -1
    }
  },
  components: {
    ModalDialogWrapper,
    LoadingSpinner,
    LayoutHeader,
    LayoutFooter,
    NalsUpgradeAvailable
  },
  watch: {
    '$store.state.user.currentUser': {
      immediate: true,
      handler: function (currentUser) {
        if (currentUser !== null) {
          NALS.initBroker(currentUser.username, this.$store.state.config.queueHost, currentUser.nalsToken)
          this.$pubSub.initWs()
          this.$pubSub.on('/blocked/'+this.currentUser.id, this.onCurrentUserBlocked)
          this.onCurrentUserBlocked()
        } else {
          this.$pubSub.closeWs()
        }
      }
    }
  },
  created () {
    this.bindEvents()
    this.onOnlinePing()
  },
  beforeDestroy () {
    this.unbindEvents()
  },
  methods: {
    bindEvents: function () {
      document.addEventListener('hello_received', this.onHelloReceived)
      document.addEventListener('bye_received', this.onByeReceived)
      document.addEventListener('nals_error_received', this.onErrorReceived)
      document.addEventListener('current_config_received', this.onCurrentConfigReceived)
      document.addEventListener('sound_card_list_received', this.onSoundCardListReceived)
      document.addEventListener('native_effects_info', this.onEffectsConfigReceived)
      document.addEventListener('cl_ping_received', this.onCLPingReceived)
      window.addEventListener('beforeunload', this.onBeforeUnload)
      this.onlinePingInterval = window.setInterval(this.onOnlinePing, 60000)
      this.updateConfigInterval = window.setInterval(this.onUpdateConfig, 300000)
      eventBus.on('open_nals_log', this.openNalsLog)
      eventBus.on('api_logout', this.onApiLogout)
    },
    unbindEvents: function () {
      document.removeEventListener('hello_received', this.onHelloReceived)
      document.removeEventListener('bye_received', this.onByeReceived)
      document.removeEventListener('nals_error_received', this.onErrorReceived)
      document.removeEventListener('current_config_received', this.onCurrentConfigReceived)
      document.removeEventListener('sound_card_list_received', this.onSoundCardListReceived)
      document.removeEventListener('native_effects_info', this.onEffectsConfigReceived)
      document.removeEventListener('cl_ping_received', this.onCLPingReceived)
      window.removeEventListener('beforeunload', this.onBeforeUnload)
      clearInterval(this.onlinePingInterval)
      eventBus.off('open_nals_log', this.openNalsLog)
      eventBus.off('api_logout', this.onApiLogout)
    },
    onHelloReceived: function (e) {
      this.$store.dispatch('nals/connect', e.detail)
      this.$store.dispatch('nals/message', { message: 'HELLO_RECEIVED', isError: false })

      // Get current config only after hello received
      NALS.getCurrentConfig()
      NALS.getSoundCardList()
      if (this.$store.getters['nals/hasNativeEffects']) {
        NALS.getNativeEffectsInfo()
      }
    },
    onByeReceived: function () {
      this.$store.dispatch('nals/disconnect')
      this.$store.dispatch('nals/clearMessages')
    },
    onErrorReceived: function (e) {
      this.$store.dispatch('nals/message', { message: e.detail.code, isError: true })
    },
    onBeforeUnload: function () {
      if (NALS.mqttConnected) {
        if (NALS.roomConnected) {
          NALS.disconnectFromRoom()
          NALS.roomConnected = false
        }
        NALS.mqttConnection.disconnect()
      }
      this.$pubSub.closeWs()
    },
    openNalsLog: function () {
      eventBus.emit('show_dialog', { component: NalsDebugConsole, size: 'small' })
    },
    onApiLogout: function () {
      if (this.$store.state.user.currentUser) {
        this.$store.dispatch('user/logout')
        this.$router.push({ name: 'login' })
      }
    },
    onOnlinePing: function () {
      if (this.$store.state.user.currentUser !== null) {
        API.pingOnline()
      }
    },
    onUpdateConfig: function () {
      API.config().then(res => {
        this.$store.dispatch('saveConfig', res)
      })
    },
    onCurrentConfigReceived: function (e) {
      this.$store.dispatch('nals/saveConfig', e.detail)
    },
    onSoundCardListReceived: function (e) {
      this.$store.dispatch('nals/setSoundcardList', e.detail.soundCardList)
    },
    onEffectsConfigReceived: function (e) {
      this.$store.dispatch('nals/saveEffectsConfig', e.detail)
    },
    onCLPingReceived: function (e) {
      eventBus.emit('cl_ping_received', e)
    },
    onCurrentUserBlocked: function () {
      API.blockedByUsers().then(res => {
        this.$store.dispatch('user/refreshBlockedByList', res)
      })
    }
  }
}
</script>

<style lang="scss">
  @import "~flatpickr/dist/flatpickr.css";
  @import "~cropperjs/dist/cropper.css";
  @import "~autocompleter/autocomplete.css";
  @import "~vue-search-select/dist/VueSearchSelect.css";
  @import "./assets/css/theme.scss";

  body, html {
    background-color: #222126;
    color: #eee;
    padding-right: 0;
    padding-left: 0;
    height: 100%;
    font-family: "Overpass", sans-serif;
  }

  @font-face {
    font-family: Overpass;
    src: local(Overpass-Regular), url("./assets/fonts/Overpass-Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: Overpass;
    src: local(Overpass-Bold), url("./assets/fonts/Overpass-Bold.ttf");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }

  .no-scroll {
    overflow: hidden;
  }

  #body {
    background-color: #222126;
    flex: 1 0 auto;
    padding-top: 1em;
  }

  /* Forms */
  .input-error-tip {
    color: rgb(233, 25, 22);
  }

  .input-error {
    border: solid 2px rgb(233, 25, 22);
    box-shadow: rgb(233, 25, 22) 0 0 0 1px;
  }

  .button + .button {
    margin-left: 1rem;
  }

  fieldset>legend {
    font-weight: bold;
  }

  label {
    color: #dfdfdf;
  }

  .skip-to-main-content-link {
    position: absolute;
    left: -9999px;
    z-index: 999;
    padding: 1em;
    background-color: black;
    color: white;
    opacity: 0;
  }

  .skip-to-main-content-link:focus {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
</style>
