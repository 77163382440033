import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import NotFoundView from '../views/NotFoundView.vue'
import {checkPrivilegesMiddleware, appStartMiddleware, updateMiddleware} from './middleware'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        name: 'not-found',
        path: '*',
        component: NotFoundView
    },
    {
        name: 'signup',
        path: '/users/signup',
        component: () => import(/* webpackChunkName: "signup" */ '../views/SignupView.vue'),
        meta: {
            guestRequired: true
        }
    },
    {
        name: 'login',
        path: '/users/login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
        meta: {
            guestRequired: true
        }
    },
    {
        name: 'validate-account',
        path: '/users/validate-account',
        component: () => import(/* webpackChunkName: "validate-account" */ '../views/ValidateAccountView.vue'),
        meta: {
            guestRequired: true
        }
    },
    {
        name: 'forgot-password',
        path: '/users/forgot-password',
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPasswordView.vue'),
        meta: {
            guestRequired: true
        }
    },
    {
        name: 'recover-password',
        path: '/users/recover-password',
        component: () => import(/* webpackChunkName: "recover-password" */ '../views/RecoverPasswordView.vue'),
        meta: {
            guestRequired: true
        }
    },
    {
        name: 'hall',
        path: '/studio/hall',
        component: () => import(/* webpackChunkName: "hall" */ '../views/HallView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'new-rehearsal-session',
        path: '/studio/sessions/new',
        component: () => import(/* webpackChunkName: "rehearsal-session" */ '../modules/rehearsalsession/views/NewView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'join-rehearsal-session',
        path: '/studio/open-rehearsal/:sessionId',
        component: () => import(/* webpackChunkName: "rehearsal-session" */ '../modules/rehearsalsession/views/JoinOpenRehearsalView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'join-test-rehearsal',
        path: '/studio/test-rehearsal/:sessionId',
        component: () => import(/* webpackChunkName: "rehearsal-session" */ '../modules/rehearsalsession/views/TestRehearsalView.vue'),
        meta: {
            roles: ['NUCORDER_MU']
        }
    },
    {
        name: 'user-profile',
        path: '/users/profiles/:username',
        component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'me',
        path: '/me',
        component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'me-preferences',
        path: '/me/preferences',
        component: () => import(/* webpackChunkName: "preferences" */ '../modules/preferences/views/IndexView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'rehearsal-sessions',
        path: '/studio/sessions',
        component: () => import(/* webpackChunkName: "rehearsal-sessions" */ '../modules/rehearsalsession/views/ListView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'nals-selfcheck',
        path: '/nals/selfcheck',
        component: () => import(/* webpackChunkName: "nals-selfcheck" */ '../modules/rehearsalsession/views/SelfCheckView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'nals-download',
        path: '/download',
        component: () => import(/* webpackChunkName: "download" */ '../modules/download/views/IndexView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'me-subscription',
        path: '/me/subscription',
        component: () => import(/* webpackChunkName: "subscription" */ '../views/SubscriptionView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'promocode',
        path: '/promo',
        component: () => import(/* webpackChunkName: "promocode" */ '../views/PromocodeView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'user-search',
        path: '/search',
        component: () => import(/* webpackChunkName: "subscription" */ '../views/ProfileSearchView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    // App start
    {
        name: 'onboarding',
        path: '/studio/onboarding',
        component: () => import(/* webpackChunkName: "onboarding" */ '../views/OnboardingView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'app-start',
        path: '/studio/welcome',
        component: () => import(/* webpackChunkName: "appstart" */ '../views/AppStartView.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'index',
        path: '/',
        redirect: {name: 'login'}
    },
    {
        name: 'private-messages',
        path: '/messages',
        component: () => import(/* webpackChunkName: "privateMessages" */ '../modules/privatemessage/views/Index.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    },
    {
        name: 'private-messages-conversation',
        path: '/messages/:conversationId',
        component: () => import(/* webpackChunkName: "privateMessages" */ '../modules/privatemessage/views/Index.vue'),
        meta: {
            roles: ['CORE_GUEST']
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Hook that launches before each Route
router.beforeEach(async (to, from, next) => {
    await updateMiddleware(to, from, next)
    await checkPrivilegesMiddleware(to, from, next)
    await appStartMiddleware(to, from, next)
    next()
})

export default router
